<template>
    <div class="card">
        <br>
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="save">
                <b-form-group id="studentId" label-for="studentId" description="">
                    <model-list-select :list="students"
                                       v-model="item.studentId"
                                       option-value="id"
                                       :custom-text="fullName"
                                       :is-error="validateState('studentId')"
                                       name="studentId"
                                       placeholder="Seleziona un allievo"
                                       aria-describedby="student-id-live-feedback">
                    </model-list-select>
                    <b-form-invalid-feedback
                        id="student-id-live-feedback"
                    >Questo campo è obbligatorio e deve contenere una data in formato ...</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="drivingLicense" label="Patente" label-for="drivingLicense" description="">
                    <b-form-select v-model="item.drivingLicenseId" :state="validateState('drivingLicenseId')">
                        <option value="0">- Scegli una Patente -</option>
                        <option v-for="drivingLicense in drivingLicenses" v-bind:value="drivingLicense.id" :key="drivingLicense.id">
                            {{ drivingLicense.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <b-form-group id="date" label="Conseguita il" label-for="date" description="">
                    <b-form-input
                        type="date"
                        v-model="item.date"
                        placeholder=""
                        :state="validateState('date')"
                        aria-describedby="date-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="date-live-feedback"
                    >Questo campo è obbligatorio e deve contenere una data in formato ...</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="expiration" label="Scadenza" label-for="expiration" description="">
                    <b-form-input
                        type="date"
                        v-model="item.expiration"
                        placeholder=""
                        :state="validateState('expiration')"
                        aria-describedby="expiration-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="expiration-live-feedback"
                    >Questo campo è obbligatorio e deve contenere una data in formato ...</b-form-invalid-feedback>
                </b-form-group>

            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="save"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import ObtainedLicenseDataService from "./ObtainedLicenseDataService";
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";
import StudentDataService from "@/components/student/StudentDataService";
import {required} from 'vuelidate/lib/validators'
import {ModelListSelect} from "vue-search-select";

export default {
    name: "obtained-license-create",
    components: {ModelListSelect},
    data() {
        return {
            item: {
                id: null,
                date: null,
                expiration: null,
                drivingLicenseId: null,
                studentId: null
            },
            drivingLicenses: [],
            students: [],
            submitted: false,
            message: '',
            errorMsg: ''
        };
    },
    validations: {
        item: {
            date: {
                required,
            },
            expiration: {
                required,
            },
            drivingLicenseId: {
                required,
            },
            studentId: {
                required,
            },
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        save() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                ObtainedLicenseDataService.create(this.item)
                    .then(response => {
                        this.item.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-obtained-license');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },
        fullName (item) {
            return `${item.firstname} ${item.lastname}`
        },
        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                this.errorMsg = e;
                console.log(e);
            });
        //
        StudentDataService.getAll()
            .then(response => {
                this.students = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
