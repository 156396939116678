<template>
    <div>
        <table class="table">
            <thead>
            <tr>
                <th scope="col">Data</th>
                <th scope="col">Inviato</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in reminders"
                :key="item.id"
                :class="{ 'table-success': item.sent }"
            >
                <td>
                    {{ item.date | dateonly }}
                    <b-icon-check-circle-fill v-if="item.sent"/>
                </td>
                <td><span v-if="item.sent">{{ item.sent | dateonly }} alle ore {{ item.sent | timeonly }}</span> </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "obtained-license-reminder-view",
    props: ['reminders'],
    filters: {
        dateonly: function (date) {
            return moment(date).format('DD/MM/YYYY');
        },
        datetime: function (date) {
            return moment(date).format('DD/MM/YYYY HH:mm:ss');
        },
        timeonly: function (date) {
            return moment(date).format('HH:mm:ss');
        },
    },
    mounted() {

    }
};
</script>

