<template>
    <div>
        <div v-if="currentObtainedLicense" class="card">
            <h1>
                {{ currentObtainedLicense.student.firstname }} {{ currentObtainedLicense.student.lastname }}
            </h1>
            <p> {{ currentObtainedLicense.student.user.email }}</p>
            <dl class="">
                <dt class="">Patente</dt>
                <dd class="">{{ currentObtainedLicense.drivingLicense.name }}</dd>
                <dt class="">Conseguita il</dt>
                <dd class=""> <b-icon icon="calendar"/> {{ currentObtainedLicense.date | moment }}</dd>
                <dt class="">Scadenza</dt>
                <dd class="" >
                    <span v-if="!renew">
                        <span v-if="isExpired(currentObtainedLicense.expiration)" class="text-danger">
                            <b-icon icon="exclamation-triangle-fill"/> Scaduta il {{ currentObtainedLicense.expiration | moment }}
                        </span>
                        <span v-else-if="isNearExpiration(currentObtainedLicense.expiration)" class="text-warning">
                            <b-icon icon="exclamation-triangle"/> In scadenza il {{ currentObtainedLicense.expiration | moment }}
                        </span>
                        <span v-else>
                            <b-icon icon="calendar"/> {{ currentObtainedLicense.expiration | moment }}
                        </span>
                    </span>&nbsp;
                    <b-button v-if="!renew" variant="outline-primary" size="sm" @click="renew = true">
                        <b-icon icon="arrow-repeat"/>
                        Rinnova
                    </b-button>
                    <obtained-license-renew v-if="renew"
                                            :obtained-license="currentObtainedLicense"
                                            v-on:closed-editor-renew="renew = false"
                                            v-on:updated-obtained-license="updatedObtainedLicense"/>
                </dd>
            </dl>
            <hr/>
            <div class="action-bar row">
                <div class="col-sm-6">
                    <b-button v-if="!renew" variant="primary" @click="sendReminder">
                        <b-icon icon="envelope"/>
                        Invia un reminder manuale
                    </b-button>
                </div>
                <div class="col-sm-6 text-right">

                </div>
            </div>

            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>

            <h3>Invio reminder automatici</h3>
            <p>Queste email vengono inviate in automati dal sistema</p>
            <obtained-license-remider-view :reminders="currentObtainedLicense.reminders"/>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a ObtainedLicense...' }}</p>
        </div>
    </div>
</template>

<script>
import ObtainedLicenseDataService from "./ObtainedLicenseDataService";
import moment from 'moment';
import ObtainedLicenseRenew from "@/components/obtained_license/ObtainedLicenseRenew";
import ObtainedLicenseRemiderView from "@/components/obtained_license/ObtainedLicenseRemiderView";

export default {
    name: "obtained-license-view",
    components: {ObtainedLicenseRenew, ObtainedLicenseRemiderView},
    data() {
        return {
            currentObtainedLicense: null,
            message: '',
            errorMsg: '',
            renew: false
        };
    },
    props: ['obtainedLicenseId'],
    methods: {
        getObtainedLicense(id) {
            ObtainedLicenseDataService.get(id)
                .then(response => {
                    this.currentObtainedLicense = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateObtainedLicense() {
            ObtainedLicenseDataService.update(this.currentObtainedLicense.id, this.currentObtainedLicense)
                .then(response => {
                    this.message = 'The class was updated successfully!';
                    this.$emit('updated-obtained-license', this.currentObtainedLicense);
                })
                .catch(e => {
                    console.log(e);
                });
        },
        updatedObtainedLicense() {
            this.renew = false
            this.$emit('updated-obtained-license', this.currentObtainedLicense);
        },
        deleteObtainedLicense() {
            if(confirm(`Vuoi cancellare l'aula ${this.currentObtainedLicense.name}?`)) {
                ObtainedLicenseDataService.delete(this.currentObtainedLicense.id)
                    .then(response => {
                        this.$emit('deleted-classroom');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },
        sendReminder() {
            if(confirm(`Sei sicuro di voler inviare un'email a ${this.currentObtainedLicense.student.firstname}?`)) {
                ObtainedLicenseDataService.sendReminder(this.currentObtainedLicense.id)
                    .then(response => {
                        this.message = "Il messaggio è stato inviato correttamente a " + this.currentObtainedLicense.student.user.email;
                        this.$emit('updated-obtained-license', this.currentObtainedLicense);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },
        isExpired(date) {
            return moment(date).isSameOrBefore(moment(), 'day');
        },
        isNearExpiration(date) {
            return moment(date).isSameOrBefore(moment().add(30, "days"), 'day');
        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
    mounted() {
        this.message = '';
        this.getObtainedLicense(this.obtainedLicenseId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
