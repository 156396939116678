<template>
    <div v-if="currentObtainedLicense">
        <div class="row">
            <div class="col-sm-7">
                <b-form >
                    <b-form-group id="expiration" label="" label-for="expiration" description="">
                        <b-form-input
                            type="date"
                            v-model="newExpiration"

                            :value="newExpiration"
                            :state="validateState('expiration')"
                            aria-describedby="expiration-live-feedback"></b-form-input>
                        <b-form-invalid-feedback
                            id="expiration-live-feedback"
                        >Questo campo è obbligatorio e deve contenere una data in formato ...</b-form-invalid-feedback>
                    </b-form-group>
                </b-form>
            </div>
            <div class="col-sm-5">
                <button class="btn btn-success btn-sm" @click="updateObtainedLicense" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                <b-button variant="link" size="sm" @click="closeEditor" :disabled="this.$store.state.loading"><b-icon icon="x"/> Annulla</b-button>
            </div>
        </div>
        <div v-if="message || errorMsg">
            <br>
            <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
            <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
        </div>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import ObtainedLicenseDataService from "@/components/obtained_license/ObtainedLicenseDataService";
import moment from 'moment';

export default {
    name: "obtained-license-renew",
    components: {},
    data() {
        return {
            currentObtainedLicense: null,
            newExpiration: null,
            message: '',
            errorMsg: ''
        };
    },
    validations: {
        newExpiration: {
            required
        }
    },
    props: ["obtainedLicense"],
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.newExpiration;
            return $dirty ? !$error : null;
        },

        updateObtainedLicense() {
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                this.currentObtainedLicense.expiration = this.newExpiration;
                ObtainedLicenseDataService.update(this.currentObtainedLicense.id, this.currentObtainedLicense)
                    .then(response => {
                        this.message = 'The practice was updated successfully!';
                        this.$emit('updated-obtained-license', this.currentObtainedLicense);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor-renew');
        }
    },
    mounted() {
        this.message = '';
        this.currentObtainedLicense = this.obtainedLicense;
        this.newExpiration = this.obtainedLicense.expiration
    }
};
</script>

<style scoped>

</style>