<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Rinnovi</h3>
            </div>

            <div class="col-md-3">
                <search-bar @search-name="search($event)"/>
            </div>

            <div class="col-md-3">
                <b-form-group id="drivingLicenseId" label="" label-for="drivingLicenseId" description="">
                    <b-form-select v-model="drivingLicenseId" @change="search()">
                        <option value="-1">TUTTE</option>
                        <option v-for="license in drivingLicenses" v-bind:value="license.id" :key="license.id">
                            {{ license.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentObtainedLicense = null">
                <b-icon icon="plus"/> Aggiungi Patente
            </button>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Studente</th>
                            <th scope="col">Scadenza</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': item.id == currentId, 'table-danger': isExpired(item.expiration), 'table-warning': isNearExpiration(item.expiration) }"
                            v-for="item in obtainedLicenses"
                            :key="item.id"
                            @click="setActiveObtainedLicense(item)"
                        >
                            <td>
                                {{ fullName(item.student) }}<br/>
                                <span class="text-muted">{{ item.drivingLicense.name }}</span>
                            </td>
                            <td>
                                <span v-if="isExpired(item.expiration)">
                                    <b-icon icon="exclamation-triangle-fill"/> Scaduta il {{ item.expiration | moment }}
                                </span>
                                <span v-else-if="isNearExpiration(item.expiration)">
                                    <b-icon icon="exclamation-triangle"/> In scadenza il {{ item.expiration | moment }}
                                </span>
                                <span v-else>
                                    {{ item.expiration | moment }}
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p v-if="this.obtainedLicenses.length == 0">Ancora nessuna patente conseguita è stata inserita</p>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <obtained-license-view :obtained-license-id="currentObtainedLicense.id" :key="currentObtainedLicense.id"
                                  v-on:updated-obtained-license="refreshCurrentObtainedLicense($event)"
                                  v-on:deleted-obtained-license="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <obtained-license-create
                        v-on:created-obtained-license="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ObtainedLicenseDataService from "./ObtainedLicenseDataService";
import ObtainedLicenseView from "./ObtainedLicenseView";
import ObtainedLicenseCreate from "./ObtainedLicenseCreate";
import SearchBar from "@/components/layout/SearchBar";
import moment from 'moment';
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";

export default {
    name: "obtained-license-index",
    components: {ObtainedLicenseView, ObtainedLicenseCreate, SearchBar},
    data() {
        return {
            obtainedLicenses: [],
            currentObtainedLicense: null,
            currentId: -1,
            drivingLicenses: [],
            drivingLicenseId: -1
        };
    },
    methods: {
        retrieveObtainedLicenses() {
            ObtainedLicenseDataService.getAll()
                .then(response => {
                    this.obtainedLicenses = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveObtainedLicenses();
            this.currentObtainedLicense = null;
            this.currentId = -1;
        },

        refreshCurrentObtainedLicense(itemObtainedLicense) {
            this.obtainedLicenses.forEach(function (item) {
                if (item.id == itemObtainedLicense.id) {
                    item.expiration = itemObtainedLicense.expiration;
                }
            });
            this.currentObtainedLicense = itemObtainedLicense;
            this.currentId = itemObtainedLicense.id;
        },

        setActiveObtainedLicense(item) {
            this.currentObtainedLicense = item;
            this.currentId = item.id;
        },

        removeAllObtainedLicenses() {
            ObtainedLicenseDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        search(name = null) {
            this.currentObtainedLicense = null;
            this.currentId = -1;
            ObtainedLicenseDataService.findByNameAndLicense(name, this.drivingLicenseId)
                .then(response => {
                    this.obtainedLicenses = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        fullName (item) {
            return `${item.firstname} ${item.lastname}`
        },
        closeEditor() {
            this.currentObtainedLicense = null;
            this.currentId = -1;
        },
        isExpired(date) {
            return moment(date).isSameOrBefore(moment(), 'day');
        },
        isNearExpiration(date) {
            return moment(date).isSameOrBefore(moment().add(30, "days"), 'day');
        }
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD/MM/YYYY');
        }
    },
    mounted() {
        this.retrieveObtainedLicenses();
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
</style>
