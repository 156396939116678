import http from "../../helpers/http-common";

class ObtainedLicenseDataService {
    getAll() {
        return http.get("/obtained_licenses");
    }

    get(id) {
        return http.get(`/obtained_licenses/${id}`);
    }

    create(data) {
        return http.post("/obtained_licenses", data);
    }

    update(id, data) {
        return http.put(`/obtained_licenses/${id}`, data);
    }

    sendReminder(id) {
        return http.put(`/obtained_licenses/send_reminder/${id}`);
    }

    delete(id) {
        return http.delete(`/obtained_licenses/${id}`);
    }

    deleteAll() {
        return http.delete('/obtained_licenses');
    }

    findByNameAndLicense(name = null, drivingLicenseId = null) {
        return http.get(`/obtained_licenses?name=${name}&drivingLicenseId=${drivingLicenseId}`);
    }
}

export default new ObtainedLicenseDataService();
